<template>
    <div class="vue-html2pdf">
		<section
			class="layout-container"
			:class="{
				'show-layout' : showLayout
			}"
		>
			<section
				class="content-wrapper"
				:style="`width: ${pdfContentWidth};`"
				ref="pdfContent"
			>
				<slot name="pdf-content"/>
			</section>
		</section>

		<transition name="transition-anim">
			<section class="pdf-preview" v-if="pdfFileUrl">
				<button @click.self="closePreview()">
					&times;
				</button>

				<iframe
					:src="pdfFileUrl"
					width="100%"
					height="100%"
				/>
			</section>
		</transition>
    </div>
</template>
<script>
import html2pdf from 'html2pdf.js'

export default {
	props: {
		showLayout: {
			type: Boolean,
			default: false
		},

		enableDownload: {
			type: Boolean,
			default: true
		},

		previewModal: {
			type: Boolean,
			default: false
		},

		paginateElementsByHeight: {
			type: Number
		},

		filename: {
			type: String,
			default: `${new Date().getTime()}`
		},

		pdfQuality: {
			type: Number,
			default: 2,
		},

		pdfFormat: {
			default: 'a4',
		},

		pdfOrientation: {
			type: String,
			default: 'portrait'
		},

		pdfContentWidth: {
			default: '800px'
		},

		htmlToPdfOptions: {
			type: Object
		},

		manualPagination: {
			type: Boolean,
			default: false
		},

		headerPage: {
			type: Object
		},
		
		footerPage: {
			type: Object
		}
	},

	data () {
		return {
			hasAlreadyParsed: false,
			progress: 0,
			pdfWindow: null,
			pdfFileUrl: null,
			hasHeader: false,
			indexHeader: 0,
			indexFooter: 0,
			page: 1,
			heightHeader: 0,
			heightFooter: 0,
			table: null,
			tbody: null,
			newTable: null,
			startRow: 0,
			maxCellWidth: [],
		}
	},

	watch: {
		progress (val) {
			this.$emit('progress', val)
		},

		paginateElementsByHeight () {
			this.resetPagination()
		},

		$props: {
			handler () {
				this.validateProps()
			},

			deep: true,
			immediate: true
		}
	},

	methods: {
		validateProps () {
			// If manual-pagination is false, paginate-elements-by-height props is required
			if (!this.manualPagination) {
				if (this.paginateElementsByHeight === undefined) {
					console.error('Error: paginate-elements-by-height is required if manual-pagination is false')
				}
			}
		},

		resetPagination () {
			const parentElement = this.$refs.pdfContent.firstChild
			const pageBreaks = parentElement.getElementsByClassName('html2pdf__page-break')
			const pageBreakLength = pageBreaks.length - 1
			
			if (pageBreakLength === -1) return

			this.hasAlreadyParsed = false

			// Remove All Page Break (For Pagination)
			for (let x = pageBreakLength; x >= 0; x--) {
				pageBreaks[x].parentNode.removeChild(pageBreaks[x])
			}
		},

		generatePdf () {
			this.$emit('hasStartedDownload')
			this.progress = 0
			this.paginationOfElements()
			this.downloadPdf()
		},

		generateFilePdf(options, templateHtml) {
			console.log(options);
			console.log(templateHtml);
		},

		paginationOfElements () {
			this.progress = 25

			/*
				When this props is true, 
				the props paginate-elements-by-height will not be used.
				Instead the pagination process will rely on the elements with a class "html2pdf__page-break"
				to know where to page break, which is automatically done by html2pdf.js
			*/
			if (this.manualPagination) {
				this.progress = 70
				this.downloadPdf()
				return
			}

			if (!this.hasAlreadyParsed) {
				const parentElement = this.$refs.pdfContent.firstChild
				const ArrOfContentChildren = Array.from(parentElement.children)
				let childrenHeight = 0
				this.page = 1;
				this.hasHeader = false

				/*
					Loop through Elements and add there height with childrenHeight variable.
					Once the childrenHeight is >= this.paginateElementsByHeight, create a div with
					a class named 'html2pdf__page-break' and insert the element before the element
					that will be in the next page
				*/
				childrenHeight = this.autoPaginate(parentElement, ArrOfContentChildren, childrenHeight)
				if(this.footerPage != undefined) {
					const footer = document.createElement('section')
					footer.classList.add('footer-page')
					const line = document.createElement('div')
					line.classList.add('line')
					footer.appendChild(line)
					const title = document.createElement('label')
					title.classList.add('footer-title')
					title.textContent = this.footerPage.titles[this.indexFooter]
					footer.appendChild(title)
					if(this.footerPage.pagination) {
						footer.classList.add('pagination-pdf')
						const pagination = document.createElement('label')
						pagination.classList.add('number-page')
						pagination.textContent = 'Page ' + this.page + ' of'
						const totalPage = document.createElement('span')
						totalPage.classList.add('total-page')
						pagination.appendChild(totalPage)
						footer.appendChild(pagination)
					}
					parentElement.append(footer)
					if(this.heightFooter == 0) {
						const height = footer.clientHeight
						this.heightFooter = height
					}
					var margin = this.paginateElementsByHeight - childrenHeight - this.heightFooter
					footer.style.marginTop = margin+'px'
				}
				var listTotalPage = Array.from(window.$('.total-page'))
				listTotalPage.forEach(element => {
					element.textContent = ' '+this.page;
				})

				this.progress = 70

				/*
					Set to true so that if would generate again we wouldn't need
					to parse the HTML to paginate the elements
				*/
				this.hasAlreadyParsed = true
			} else {
				this.progress = 70
			}
		},

		autoPaginate(parentElement, ArrOfContentChildren, childrenHeight) {
			if(parentElement.localName == 'table') {
				this.table = parentElement
			} else if(parentElement.localName == 'tbody') {
				this.tbody = parentElement
			}
			for (const childElement of ArrOfContentChildren) {
				// Get The First Class of the element
				const elementFirstClass = childElement.classList[0]
				const isPageBreakClass = elementFirstClass === 'html2pdf__page-break'
				if (isPageBreakClass) {
					if(this.footerPage != undefined) {
						if(childElement.classList.contains('change-footer')) {
							this.indexFooter++
						}
						this.addFooter(parentElement, childElement, childrenHeight)
					}
					this.page++
					childrenHeight = 0
					this.hasHeader = false
					if(this.footerPage != undefined) {
						if(childElement.classList.contains('change-header')) {
							this.indexHeader++
						}
					}
				} else {
					if(childrenHeight == 0 && this.headerPage != undefined && !this.hasHeader) {
						this.addHeader(parentElement, childElement)
						childrenHeight = this.heightHeader
					}

					// Get Element Height
					var elementHeight = childElement.clientHeight

					// Get Computed Margin Top and Bottom
					const elementComputedStyle = childElement.currentStyle || window.getComputedStyle(childElement)
					const elementMarginTopBottom = parseInt(elementComputedStyle.marginTop) + parseInt(elementComputedStyle.marginBottom)

					// Add Both Element Height with the Elements Margin Top and Bottom
					const elementHeightWithMargin = elementHeight + elementMarginTopBottom
					
					if ((childrenHeight + elementHeight + this.heightFooter) < this.paginateElementsByHeight) {
						childrenHeight += elementHeightWithMargin
					} else if(this.hasBrowseChildren(childElement, childrenHeight, elementHeight)){
						var array = Array.from(childElement.children)
						childrenHeight = this.autoPaginate(childElement, array, childrenHeight)
					} else {
						if(childElement.localName == 'tr'){
							this.createNewTable(childElement)
							do {
								this.table.deleteRow(this.startRow);
							}while(this.startRow < this.table.rows.length)
							childrenHeight = this.heightHeader + elementHeightWithMargin
							return childrenHeight
						} else {
							this.pagination(parentElement, childElement, childrenHeight)
							childrenHeight = this.heightHeader + elementHeightWithMargin
						}
					}
				}
			}
			return childrenHeight;
		},

		hasBrowseChildren(childElement, childrenHeight, elementHeight){
			if(childElement.localName == 'tr') {
				return false
			} else {
				if(childElement.firstElementChild != undefined && childElement.firstElementChild != null){
					var firstChild = childElement.firstElementChild
					if(firstChild.nextElementSibling != undefined && firstChild.nextElementSibling != null) {
						var firstHeight = firstChild.clientHeight
						const elementComputedStyle = firstChild.currentStyle || window.getComputedStyle(firstChild)
						const elementMarginTopBottom = parseInt(elementComputedStyle.marginTop) + parseInt(elementComputedStyle.marginBottom)

						// Add Both Element Height with the Elements Margin Top and Bottom
						const elementHeightWithMargin = firstHeight + elementMarginTopBottom
						if(elementHeightWithMargin == elementHeight) {
							return false;
						} else {
							return true;
						}
					} else {
						return true;
					}
				} else {
					return false;
				}
			}
		},

		createNewTable(childElement) {
			this.newTable = document.createElement('table')
			this.newTable.align = this.table.align
			this.newTable.border = this.table.border
			this.newTable.cellpadding = this.table.cellpadding
			this.newTable.cellspacing = this.table.cellspacing
			var rowCount = 0;
			this.startRow = this.table.rows.length;
			var listRow = Array.from(this.table.rows)
			this.maxCellWidth = []
			listRow.forEach((element, index) =>{
				if(element == childElement || index > this.startRow) {
					if(this.startRow > index) {
						this.startRow = index
					}
					var row = this.newTable.insertRow(rowCount);
					var listCell = Array.from(element.cells)
					listCell.forEach((element, index) => {
						var cell = row.insertCell(index)
						cell.innerHTML = element.innerHTML
						cell.classList = element.classList
						if(this.maxCellWidth.length >= index+1) {
							if(this.maxCellWidth[index] < element.clientWidth) {
								this.maxCellWidth[index] = element.clientWidth
							}
						} else {
							this.maxCellWidth.push(element.clientWidth)
						}
					})
					listCell = Array.from(this.newTable.rows[0].cells)
					listCell.forEach((element, index) => {
						element.style.width = this.maxCellWidth[index]+'px'
						this.table.rows[0].cells[index].style.width = this.maxCellWidth[index]+'px'
					})
					rowCount++
				}
			})
			this.table.after(this.newTable)
			this.page++
			if(this.headerPage != undefined) {
				const header = document.createElement('section')
				header.classList.add('header-page')
				const title = document.createElement('label')
				title.classList.add('header-title')
				title.textContent = this.headerPage.titles[this.indexHeader]
				header.appendChild(title)
				if(this.headerPage.pagination) {
					header.classList.add('pagination-pdf')
					const pagination = document.createElement('label')
					pagination.classList.add('number-page')
					pagination.textContent = 'Page ' + this.page + ' of'
					const totalPage = document.createElement('span')
					totalPage.classList.add('total-page')
					pagination.appendChild(totalPage)
					header.appendChild(pagination)
				}
				const line = document.createElement('div')
				line.classList.add('line')
				header.appendChild(line)
				this.table.after(header)
				this.hasHeader = true
			}

			const section = document.createElement('div')
			section.classList.add('html2pdf__page-break')
			this.table.after(section)

			if(this.footerPage != undefined) {
				const footer = document.createElement('section')
				footer.classList.add('footer-page')
				const line = document.createElement('div')
				line.classList.add('line')
				footer.appendChild(line)
				const title = document.createElement('label')
				title.classList.add('footer-title')
				title.textContent = this.footerPage.titles[this.indexFooter]
				footer.appendChild(title)
				if(this.footerPage.pagination) {
					footer.classList.add('pagination-pdf')
					const pagination = document.createElement('label')
					pagination.classList.add('number-page')
					pagination.textContent = 'Page ' + this.page + ' of'
					const totalPage = document.createElement('span')
					totalPage.classList.add('total-page')
					pagination.appendChild(totalPage)
					footer.appendChild(pagination)
				}
				this.table.after(footer)
				if(this.heightFooter == 0) {
					const height = footer.clientHeight
					this.heightFooter = height
				}
			}
		},

		pagination(parentElement, childElement, childrenHeight) {
			if(this.footerPage != undefined) {
				this.addFooter(parentElement, childElement, childrenHeight)
			}
			const section = document.createElement('div')
			section.classList.add('html2pdf__page-break')
			parentElement.insertBefore(section, childElement)
			// Reset Variables made the upper condition false
			this.page++
			if(this.headerPage != undefined) {
				this.addHeader(parentElement, childElement)
			}
		},

		addHeader(parentElement, childElement) {
			const header = document.createElement('section')
			header.classList.add('header-page')
			const title = document.createElement('label')
			title.classList.add('header-title')
			title.textContent = this.headerPage.titles[this.indexHeader]
			header.appendChild(title)
			if(this.headerPage.pagination) {
				header.classList.add('pagination-pdf')
				const pagination = document.createElement('label')
				pagination.classList.add('number-page')
				pagination.textContent = 'Page ' + this.page + ' of'
				const totalPage = document.createElement('span')
				totalPage.classList.add('total-page')
				pagination.appendChild(totalPage)
				header.appendChild(pagination)
			}
			const line = document.createElement('div')
			line.classList.add('line')
			header.appendChild(line)
			parentElement.insertBefore(header, childElement)
			if(this.heightHeader == 0) {
				const height = header.clientHeight
				const elementComputedStyle = header.currentStyle || window.getComputedStyle(header)
				const elementMarginTopBottom = parseInt(elementComputedStyle.marginTop) + parseInt(elementComputedStyle.marginBottom)

				// Add Both Element Height with the Elements Margin Top and Bottom
				this.heightHeader = height + elementMarginTopBottom
			}
			this.hasHeader = true
		},

		addFooter(parentElement, childElement, childrenHeight) {
			const footer = document.createElement('section')
			footer.classList.add('footer-page')
			const line = document.createElement('div')
			line.classList.add('line')
			footer.appendChild(line)
			const title = document.createElement('label')
			title.classList.add('footer-title')
			title.textContent = this.footerPage.titles[this.indexFooter]
			footer.appendChild(title)
			if(this.footerPage.pagination) {
				footer.classList.add('pagination-pdf')
				const pagination = document.createElement('label')
				pagination.classList.add('number-page')
				pagination.textContent = 'Page ' + this.page + ' of'
				const totalPage = document.createElement('span')
				totalPage.classList.add('total-page')
				pagination.appendChild(totalPage)
				footer.appendChild(pagination)
			}
			parentElement.insertBefore(footer, childElement)
			if(this.heightFooter == 0) {
				const height = footer.clientHeight
				this.heightFooter = height
			}
			var margin = this.paginateElementsByHeight - childrenHeight - this.heightFooter
			footer.style.marginTop = margin+'px'
		},

		async downloadPdf () {
			// Set Element and Html2pdf.js Options
			const element = this.$refs.pdfContent
			let opt = this.setOptions()
			let pdfBlobUrl = await html2pdf().set(opt).from(element).output('bloburl')

			if (this.previewModal) {
				this.pdfFileUrl = pdfBlobUrl
			}

			if (this.enableDownload) {
				pdfBlobUrl = await html2pdf().set(opt).from(element).save().output('bloburl')
			}

			const res = await fetch(pdfBlobUrl)
			const pdfFile = await res.blob()
			this.progress = 100
			this.$emit('hasGenerated', pdfFile)
		},

		setOptions () {
			if (this.htmlToPdfOptions !== undefined && this.htmlToPdfOptions !== null) {
				return this.htmlToPdfOptions
			}

			return {
				margin: 0,

				filename: `${this.filename}.pdf`,

				image: {
					type: 'jpeg', 
					quality: 0.98
				},

				enableLinks: false,

				html2canvas: {
					scale: this.pdfQuality,
					useCORS: true
				},

				jsPDF: {
					unit: 'in',
					format: this.pdfFormat,
					orientation: this.pdfOrientation
				}
			}
		},

		closePreview () {
			this.pdfFileUrl = null
		}
	}
}
</script>

<style lang="scss" scoped>
.vue-html2pdf {
	.layout-container {
		position: fixed;
		width: 100vw;
		height: 100vh;
		left: -100vw;
		top: 0;
		z-index: -9999;
		background: rgba(95, 95, 95, 0.8);
		display: flex;
		justify-content: center;
		align-items: flex-start;
		overflow: auto;

		&.show-layout {
			left: 0vw;
			z-index: 9999;
		}
	}

	.pdf-preview {
		position: fixed;
		width: 65%;
		min-width: 600px;
		height: 80vh;
		top: 100px;
		z-index: 9999999;
		left: 50%;
		transform: translateX(-50%);
		border-radius: 5px;
		box-shadow: 0px 0px 10px #00000048;

		button {
			position: absolute;
			top: -20px;
			left: -15px;
			width: 35px;
			height: 35px;
			background: #555;
			border: 0;
			box-shadow: 0px 0px 10px #00000048;
			border-radius: 50%;
			color: #fff;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 20px;
			cursor: pointer;
		}

		iframe {
			border: 0;
		}
	}

	.transition-anim-enter-active, .transition-anim-leave-active {
		transition: opacity 0.3s ease-in;
	}

	.transition-anim-enter, .transition-anim-leave-to{
		opacity: 0;
	}
}
</style>
