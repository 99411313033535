import * as Utils from './Utils'
// import * as Logs from './Logs'
var templateHTML = ''

function payslip(company, payroll, payrollDetail, infoEmployee, summary) {
    var infoPlus = ""
    var infoPlusTax = ""
    var option = ""
    var type = ""
    payrollDetail.listExtraHours.forEach(item => {
        if(item.option == 'ORDINARY_HOURS') {
            option = 'Ordinary hours'
        } else {
            option = 'Overtime - exempt from super'
        }
        infoPlus += `
        <tr>
            <td class='table-text text-left'>${item.name}</td>
            <td class='table-text text-right'>${item.hour}</td>
            <td class='table-text text-right'>$${roundNumber(item.rate)}</td>
            <td class='table-text text-right'>$${roundNumber(item.hour * item.rate)}</td>
            <td class='table-text text-center'>${option}</td>
        </tr>`
    })
    if(payrollDetail.listAllowance.length) {
        var listTaxAndSuper = ''
        var index1 = 0;
        var listTaxExemptSuper = ''
        var index2 = 0;
        var listExemptTaxAndSuper = ''
        var index3 = 0;
        payrollDetail.listAllowance.forEach(item => {
            switch(item.type) {
                case 'CAR':
                    type = 'Car'
                    break;
                case 'TRANSPORT':
                    type = 'Transport'
                    break;
                case 'LAUNDRY':
                    type = 'Laundry'
                    break;
                case 'MEALS':
                    type = 'Meals'
                    break;
                case 'TRAVEL':
                    type = 'Travel'
                    break;
                case 'OTHER':
                    if(item.noteJobKeeper != null && item.noteJobKeeper != "") {
                        type = 'JobKeeper'
                    }
                    type = 'Other'
                    break;
            }
            var code = `
                <td class='table-text text-right'>${item.unit}</td>
                <td class='table-text text-right'>$${roundNumber(item.cost)}</td>
                <td class='table-text text-right'>$${roundNumber(item.unit * item.cost)}</td>
                <td class='table-text text-center'>${type}</td>
            </tr>`
            switch(item.option) {
                case 'SUBJECT_TO_TAX_AND_SUPER':
                    index1++
                    if(index1 != 1) {
                        listTaxAndSuper += '<tr>'
                    }
                    listTaxAndSuper += code
                    break;
                case 'SUBJECT_TO_TAX_EXEMPT_FROM_SUPER':
                    index2++
                    if(index2 != 1) {
                        listTaxExemptSuper += '<tr>'
                    }
                    listTaxExemptSuper += code
                    break;
                case 'EXEMPT_FROM_TAX_AND_SUPER':
                    index3++
                    if(index3 != 1) {
                        listExemptTaxAndSuper += '<tr>'
                    }
                    listExemptTaxAndSuper += code
                    break;
            }
        })
        console.log('old: '+listTaxAndSuper);
        if(listTaxAndSuper != "") {
            listTaxAndSuper = `<tr>
            <td rowspan='${index1}' class='table-text text-left'>Allowances - Subject to tax and super</td>` + listTaxAndSuper
            console.log('new: '+listTaxAndSuper);
        }
        if(listTaxExemptSuper != "") {
            listTaxExemptSuper = `<tr>
            <td rowspan='${index2}' class='table-text text-left'>Allowances - Subject to tax, Exempt from super</td>` + listTaxExemptSuper
        }
        if(listExemptTaxAndSuper != "") {
            listExemptTaxAndSuper = `<tr>
            <td rowspan='${index3}' class='table-text text-left'>Allowances - Exempt from tax and super</td>` + listExemptTaxAndSuper
        }
        infoPlus += listTaxAndSuper + listTaxExemptSuper + listExemptTaxAndSuper
    }
    payrollDetail.listBonous.forEach(item => {
        switch(item.type) {
            case 'EXEMPT_TO_SUPER':
                type = 'Exempt from super'
                break;
            case 'SUBJECT_TO_SUPER':
                type = 'Subject to super'
                break;
        }
        infoPlus += `
        <tr>
            <td colspan='3' class='table-text text-left'>Bonus</td>
            <td class='table-text text-right'>$${roundNumber(item.amountBonus)}</td>
            <td class='table-text text-center'>${type}</td>
        </tr>`
    })
    if(payrollDetail.listTermination.length > 0) {
        var indexTer = 1
        var unit = 0
        var rate = 0
        payrollDetail.listTermination.forEach(item => {
            unit += item.unusedLeave
            rate += item.amountTermination
        })
        var terminationHtml = `
                <td class='table-text text-right'>${roundNumber(unit, 'RQ')}</td>
                <td class='table-text text-right'>$${roundNumber(rate)}</td>
                <td class='table-text text-right'>$${roundNumber(payrollDetail.terminationPay)}</td>
                <td class='table-text text-center'>Unused Annual Leave</td>
            </tr>`
        var taxTerminationHtml = `
            <tr>
                <td colspan='3' class='table-text text-left'>Tax on unused leave</td>
                <td class='table-text text-right'>$${roundNumber(payrollDetail.taxTerminationPay)}</td>
                <td class='table-text text-center'></td>
            </tr>`
        if(payrollDetail.etpCodeR > 0) {
            indexTer++
            terminationHtml += `
            <tr>
                <td class='table-text text-right'></td>
                <td class='table-text text-right'></td>
                <td class='table-text text-right'>$${roundNumber(payrollDetail.etpCodeR)}</td>
                <td class='table-text text-center'>Redundancy (Type R)</td>
            </tr>`
            taxTerminationHtml += `
            <tr>
                <td colspan='3' class='table-text text-left'>Tax on redundancy (Type R)</td>
                <td class='table-text text-right'>$${roundNumber(payrollDetail.taxETPTypeR)}</td>
                <td class='table-text text-center'></td>
            </tr>`
        }
        if(payrollDetail.lumSumD > 0) {
            indexTer++
            terminationHtml += `
            <tr>
                <td class='table-text text-right'></td>
                <td class='table-text text-right'></td>
                <td class='table-text text-right'>$${roundNumber(payrollDetail.lumSumD)}</td>
                <td class='table-text text-center'>Redundancy (Lump sum D)</td>
            </tr>`
        }
        if(payrollDetail.etpTypeO > 0) {
            indexTer++
            terminationHtml += `
            <tr>
                <td class='table-text text-right'></td>
                <td class='table-text text-right'></td>
                <td class='table-text text-right'>$${roundNumber(payrollDetail.etpTypeO)}</td>
                <td class='table-text text-center'>ETP Type O</td>
            </tr>`
            taxTerminationHtml += `
            <tr>
                <td colspan='3' class='table-text text-left'>Tax on ETP Type O</td>
                <td class='table-text text-right'>$${roundNumber(payrollDetail.taxETPTypeO)}</td>
                <td class='table-text text-center'></td>
            </tr>`
        }
        infoPlus += `
        <tr>
            <td rowspan='${indexTer} class='table-text text-left'>Termination</td>
            ${terminationHtml}`
        infoPlusTax += `${taxTerminationHtml}`
    }
    payrollDetail.listDeduction.forEach(item => {
        switch(item.option) {
            case 'PRE_TAX_DEDUCTION':
                option = 'Pre tax deduction'
                break;
            case 'POST_TAX_DEDUCTION':
                option = 'Post tax deduction'
                break;
        }
        switch(item.type) {
            case 'FEES':
                type = 'Fees'
                break;
            case 'WORKPLACE_GIVING':
                type = 'Workplace Giving'
                break;
        }
        infoPlus += `
        <tr>
            <td colspan='3' class='table-text text-left'>Deduction - ${option}</td>
            <td class='table-text text-right'>$${roundNumber(item.amountDeduction)}</td>
            <td class='table-text text-center'>${type}</td>
        </tr>`
    })
    templateHTML = `
        <div style='display: flex; margin-bottom: 20px'>
            <div style='width: 70%; font-size: 10px'>
                <label><b>Company Name: </b>${company.businessName}</label><br>
                <label><b>ABN: </b>${company.companyABN}</label><br>
                <label><b>Address: </b>${company.address1}</label><br>
                <label><b>Employee: </b>${infoEmployee.firstName + ' ' + infoEmployee.lastName}</label><br>
                <label><b>Job Title: </b>${infoEmployee.job}</label><br>
                <label><b>Hourly Rate: </b>$${roundNumber(infoEmployee.payRatePerHour)}</label><br>
                <label><b>Pay Reriod: </b>${formatDate(payroll.startPayPeriod) + ' - ' + formatDate(payroll.endPayPeriod)}</label><br>
                <label><b>Payment Date: </b>${formatDate(payroll.paymentDate)}</label><br>
                <label><b>Gross Pay: </b>$${roundNumber(summary.totalEarnings)}</label><br>
                <label><b>Net Pay: </b>$${roundNumber(summary.totalNetPay)}</label><br>
            </div>
            <div style='width: 30%; padding: 10px;'>
                <img src="/assets/img/information.jpg" style='width: 150px'>
            </div>
        </div>
        <table align='center' border='0' cellpadding='0' cellspacing='0'>
            <tr>
                <th class='table-title text-left'>SALARY & WAGE</th>
                <th class='table-title text-right'>Units</th>
                <th class='table-title text-right'>Rate</th>
                <th class='table-title text-right'>This Pay</th>
                <th class='table-title text-center'>Type</th>
            </tr>
            <tr>
                <td class='table-text text-left'>Base Ordinary Hours</td>
                <td class='table-text text-right'>${payrollDetail.ordinaryWorkHours}</td>
                <td class='table-text text-right'>$${roundNumber(payrollDetail.ordinaryPayRate)}</td>
                <td class='table-text text-right'>$${roundNumber(payrollDetail.ordinaryWorkHours * payrollDetail.ordinaryPayRate)}</td>
                <td class='table-text text-center'>${infoEmployee.payPeriod}</td>
            </tr>
            ${infoPlus}
            <tr>
                <th colspan='3' class='table-title text-left'>TAX</th>
                <th class='table-title text-right'>This Pay</th>
                <th class='table-title text-center'></th>
            </tr>
            <tr>
                <td colspan='3' class='table-text text-left'>PAYG</td>
                <td class='table-text text-right'>$${roundNumber(payrollDetail.tax)}</td>
                <td class='table-text text-center'></td>
            </tr>
            ${infoPlusTax}
            <tr>
                <th colspan='3' class='table-title text-left'>SUPER CONTRIBUTIONS</th>
                <th class='table-title text-right'>This Pay</th>
                <th class='table-title text-center'></th>
            </tr>
            <tr>
                <td colspan='3' class='table-text text-left'>Super Guarantee</td>
                <td class='table-text text-right'>$${roundNumber(payrollDetail.super)}</td>
                <td class='table-text text-center'></td>
            </tr>
            <tr>
                <td colspan='3' class='table-text text-left'>Super Salary Sacrifce</td>
                <td class='table-text text-right'>$00.00</td>
                <td class='table-text text-center'></td>
            </tr>
            <tr>
                <th colspan='2' class='table-title text-left'>Accrued Entitlements</th>
                <th class='table-title text-right'>Accrued</th>
                <th class='table-title text-right'>Used</th>
                <th class='table-title text-right'>Balance</th>
            </tr>
            <tr>
                <td colspan='2' class='table-text text-left'>Annual Leave (hours)</td>
                <td class='table-text text-right'>${roundNumber(payrollDetail.annualLeaveAccrued)}</td>
                <td class='table-text text-right'>${roundNumber(payrollDetail.annualLeaveUsed)}</td>
                <td class='table-text text-right'>${roundNumber(payrollDetail.totalAnnualLeave - payrollDetail.annualLeaveAccrued)}</td>
            </tr>
            <tr>
                <td colspan='2' class='table-text text-left'>Slick Leave (hours)</td>
                <td class='table-text text-right'>${roundNumber(payrollDetail.sickLeaveAccrued)}</td>
                <td class='table-text text-right'>${roundNumber(payrollDetail.sickLeaveUsed)}</td>
                <td class='table-text text-right'>${roundNumber(payrollDetail.totalSickLeave - payrollDetail.sickLeaveAccrued)}</td>
            </tr>
            <tr style='background-color: Cyan'>
                <th colspan='3' class='table-title text-left'>PAYMENT DETAILS</th>
                <th class='table-title text-right'>Amount</th>
                <th class='table-title text-center'></th>
            </tr>
            <tr>
                <td colspan='3' class='table-text text-left'>Cash</td>
                <td class='table-text text-right'>$${roundNumber(payrollDetail.amountPaid)}</td>
                <td class='table-text text-center'></td>
            </tr>
        </table>`
    return templateHTML;
}

function employeeContact(data) {
    var dataRow = "";
    data.listEmployee.forEach(employee => {
        dataRow += `<tr>
                        <td class='table-text text-left'>${employee.firstName + ' ' + employee.lastName}</td>
                        <td class='table-text text-left'>${employee.email}</td>
                        <td class='table-text text-left'>${employee.contactNumber}</td>
                        <td class='table-text text-left'>${employee.address1}</td>
                    </tr>`;
    });
    templateHTML = `
        <div style='margin-bottom: 20px'>
            <label style='font-size: 14px;'><b>Company Name: </b>${data.company.businessName}</label>
            <b style='font-size: 18px; float: right;'>Employee Contact Report</b>
        </div>
        <table align='center' border='0' cellpadding='0' cellspacing='0'>
            <tr>
                <th class='table-title text-left'>Name</th>
                <th class='table-title text-left'>Email</th>
                <th class='table-title text-left'>Phone</th>
                <th class='table-title text-left'>Address</th>
            </tr>
            ${dataRow}
        </table>`
    return templateHTML;
}

function employeeRemuneration(data) {
    var dataRow = "";
    data.listEmployee.forEach(employee => {
        dataRow += `<tr>
                        <td class='table-text text-left'>${employee.firstName + ' ' + employee.lastName}</td>
                        <td class='table-text text-left'>${formatDateToString(employee.startDate)}</td>
                        <td class='table-text text-left'>${employee.terminationDate != null ? formatDateToString(employee.terminationDate) : ''}</td>
                        <td class='table-text text-center'>${employee.employmentType}</td>
                        <td class='table-text text-center'>${employee.payPeriod}</td>
                        <td class='table-text text-right'>${employee.weeklyWorkHours}</td>
                        <td class='table-text text-right'>$${roundNumber(employee.payRate)}</td>
                        <td class='table-text text-right'>$${roundNumber(employee.weeklyWorkHours * employee.payRatePerHour * 52)}</td>
                    </tr>`;
    });
    templateHTML = `
        <div style='margin-bottom: 20px'>
            <label style='font-size: 14px;'><b>Company Name: </b>${data.company.businessName}</label>
            <b style='font-size: 18px; float: right;'>Employee Remuneration Report</b>
        </div>
        <table align='center' border='0' cellpadding='0' cellspacing='0'>
            <tr>
                <th class='table-title text-left'>Employee</th>
                <th class='table-title text-left'>Start Date</th>
                <th class='table-title text-left'>Termination Date</th>
                <th class='table-title text-center'>Employment Basis</th>
                <th class='table-title text-center'>Payrun Calendar</th>
                <th class='table-title text-right'>Weekly work hours</th>
                <th class='table-title text-right'>Pay Rate</th>
                <th class='table-title text-right'>Annual Salary</th>
            </tr>
            ${dataRow}
        </table>`
    return templateHTML;
}

function leaveBalances(data) {
    var dataRow = "";
    data.listReportLeaveBalances.forEach(employee => {
        dataRow += `<tr>
                        <td rowspan='2' class='table-text text-left'>${employee.firstName + ' ' + employee.lastName}</td>
                        <td class='table-text text-right'>Annual Leave</td>
                        <td class='table-text text-right'>${roundNumber(employee.totalAnnualLeaveUsed)}</td>
                        <td class='table-text text-right'>${roundNumber(employee.totalAnnualLeaveUsed * employee.payRatePerHour)}</td>
                    </tr>
                    <tr>
                        <td class='table-text text-right'>Sick/Carer's Leave</td>
                        <td class='table-text text-right'>${roundNumber(employee.totalSickLeaveUsed)}</td>
                        <td class='table-text text-right'>${roundNumber(employee.totalSickLeaveUsed * employee.payRatePerHour)}</td>
                    </tr>`;
    });
    templateHTML = `
        <div style='margin-bottom: 20px'>
            <label style='font-size: 14px;'><b>Company Name: </b>${data.company.businessName}</label>
            <b style='font-size: 18px; float: right;'>Leave Balances Report</b>
        </div>
        <span style='float: right;'>As at ${formatDateToString(data.toDate)}</span>
        <table align='center' border='0' cellpadding='0' cellspacing='0'>
            <tr>
                <th class='table-title text-left'>Employee</th>
                <th class='table-title text-right'>Leave Type</th>
                <th class='table-title text-right'>Hours</th>
                <th class='table-title text-right'>Value</th>
            </tr>
            ${dataRow}
        </table>`;
    return templateHTML;
}

function payrollActivitySummary(data) {
    var dataRow = "";
    var totalOrdinaryHours = 0;
    var totalGrossEarnings = 0;
    var totalTax = 0;
    var totalSuper = 0;
    var totalNetPay = 0;
    var totalAmountPaid = 0;
    data.listReportSummary.forEach(item => {
        totalOrdinaryHours += item.ordinaryHours;
        totalGrossEarnings += item.grossEarnings;
        totalTax += item.tax;
        totalSuper += item.super;
        totalNetPay += item.netPay;
        totalAmountPaid += item.amountPaid;
    });
    dataRow = `<tr>
                    <td class='table-text text-left'>Salary & Wage</td>
                    <td class='table-text text-right'></td>
                    <td class='table-text text-right'></td>
                </tr>
                <tr>
                    <td class='table-text text-left' style='padding-left: 20px;'>Ordinary Hours</td>
                    <td class='table-text text-right'>${totalOrdinaryHours}</td>
                    <td class='table-text text-right'>$${roundNumber(totalGrossEarnings)}</td>
                </tr>

                <tr>
                    <td class='table-text text-left'>Tax</td>
                    <td class='table-text text-right'></td>
                    <td class='table-text text-right'></td>
                </tr>
                <tr>
                    <td class='table-text text-left' style='padding-left: 20px;'>PAYG</td>
                    <td class='table-text text-right'></td>
                    <td class='table-text text-right'>$${roundNumber(totalTax)}</td>
                </tr>

                <tr>
                    <td class='table-text text-left'>Super Contributions</td>
                    <td class='table-text text-right'></td>
                    <td class='table-text text-right'></td>
                </tr>
                <tr>
                    <td class='table-text text-left' style='padding-left: 20px;'>Super Guarantee</td>
                    <td class='table-text text-right'></td>
                    <td class='table-text text-right'>$${roundNumber(totalSuper)}</td>
                </tr>

                <tr>
                    <td class='table-text text-left'>Net Pay</td>
                    <td class='table-text text-right'></td>
                    <td class='table-text text-right'></td>
                </tr>
                <tr>
                    <td class='table-text text-left' style='padding-left: 20px;'>Net Pay</td>
                    <td class='table-text text-right'></td>
                    <td class='table-text text-right'>$${roundNumber(totalNetPay)}</td>
                </tr>

                <tr>
                    <td class='table-text text-left'>Amount Paid</td>
                    <td class='table-text text-right'></td>
                    <td class='table-text text-right'></td>
                </tr>
                <tr>
                    <td class='table-text text-left' style='padding-left: 20px;'>Amount Paid</td>
                    <td class='table-text text-right'></td>
                    <td class='table-text text-right'>$${roundNumber(totalAmountPaid)}</td>
                </tr>
                `;
    templateHTML = `
        <div style='margin-bottom: 20px'>
            <label style='font-size: 14px;'><b>Company Name: </b>${data.company.businessName}</label>
            <b style='font-size: 18px; float: right;'>Payroll Activity Detail</b>
        </div>
        <span style='float: right;'>${formatDateToString(data.fromDate)} ~ ${formatDateToString(data.toDate)}</span>
        <table align='center' border='0' cellpadding='0' cellspacing='0'>
            <tr>
                <th class='table-title text-left'>Item</th>
                <th class='table-title text-right'>Hours</th>
                <th class='table-title text-right'>Amount</th>
            </tr>
            ${dataRow}
        </table>`;
    return templateHTML;
}

function payrollEmployeeSummary(data) {
    var dataRow = "";
    var totals = {
        grossEarnings: 0,
        allowance: 0,
        deduction: 0,
        tax: 0,
        super: 0,
        netPay: 0,
        amountPaid: 0,
    };
    data.listReportSummary.forEach(employee => {
        totals.grossEarnings += employee.grossEarnings;
        totals.allowance += employee.allowance;
        totals.deduction += employee.deduction;
        totals.tax += employee.tax;
        totals.super += employee.super;
        totals.netPay += employee.netPay;
        totals.amountPaid += employee.amountPaid;
        dataRow += `<tr>
                        <td class='table-text text-left'>${employee.firstName + ' ' + employee.lastName}</td>
                        <td class='table-text text-right'>$${roundNumber(employee.grossEarnings)}</td>
                        <td class='table-text text-right'>$${roundNumber(employee.allowance)}</td>
                        <td class='table-text text-right'>$${roundNumber(employee.deduction)}</td>
                        <td class='table-text text-right'>$${roundNumber(employee.tax)}</td>
                        <td class='table-text text-right'>$${roundNumber(employee.super)}</td>
                        <td class='table-text text-right'>$${roundNumber(employee.netPay)}</td>
                        <td class='table-text text-right'>$${roundNumber(employee.amountPaid)}</td>
                    </tr>`;
    });
    templateHTML = `
        <div style='margin-bottom: 20px'>
            <label style='font-size: 14px;'><b>Company Name: </b>${data.company.businessName}</label>
            <b style='font-size: 18px; float: right;'>Payroll Employee Summary Report</b>
        </div>
        <span style='float: right;'>${formatDateToString(data.fromDate)} ~ ${formatDateToString(data.toDate)}</span>
        <table align='center' border='0' cellpadding='0' cellspacing='0'>
            <tr>
                <th class='table-title text-left'>Employee</th>
                <th class='table-title text-right'>Earnings</th>
                <th class='table-title text-right'>Allowance</th>
                <th class='table-title text-right'>Deduction</th>
                <th class='table-title text-right'>Tax</th>
                <th class='table-title text-right'>Super</th>
                <th class='table-title text-right'>Net Pay</th>
                <th class='table-title text-right'>Amount Paid</th>
            </tr>
            ${dataRow}
            <tr>
                <td class='table-text text-left text-red'>total</td>
                <td class='table-text text-right text-red'>$${roundNumber(totals.grossEarnings)}</td>
                <td class='table-text text-right text-red'>$${roundNumber(totals.allowance)}</td>
                <td class='table-text text-right text-red'>$${roundNumber(totals.deduction)}</td>
                <td class='table-text text-right text-red'>$${roundNumber(totals.tax)}</td>
                <td class='table-text text-right text-red'>$${roundNumber(totals.super)}</td>
                <td class='table-text text-right text-red'>$${roundNumber(totals.netPay)}</td>
                <td class='table-text text-right text-red'>$${roundNumber(totals.amountPaid)}</td>
            </tr>
        </table>`
    return templateHTML;
}

function formatDateToString(inputDate) {
    var m_names = new Array("Jan", "Feb", "Mar",
        "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec");
    var date = new Date(inputDate);
    var day = date.getDate();
    var month = date.getMonth();
    var year = date.getFullYear();
    if(day < 10) {
        day = '0'+day;
    }
    var dateShow = day + " " + m_names[month] + " " + year;
    //day + "-" +
    return dateShow
}
function formatDate(inputDate) {
    var date = new Date(inputDate);
    var day = date.getDate();
    var month = date.getMonth()+1;
    var year = date.getFullYear();
    if(day < 10) {
        day = '0'+day;
    }
    if(month < 10) {
        month = '0'+month;
    }
    var dateShow = day + "/" + month + "/" + year;
    //day + "-" +
    return dateShow
}
function roundNumber(number, style) {
    if (style != undefined && style == 'RQ' && number != 0) {
        return Number(Utils.roundNumber(number).replace(/,/g, ''))
    } else {
        return Utils.roundNumber(number)
    }
}

export {
    payslip,
    employeeContact,
    employeeRemuneration,
    leaveBalances,
    payrollActivitySummary,
    payrollEmployeeSummary
}
