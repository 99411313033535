<template>
<div class='ng-scope' style="padding: 2rem 2.5rem; ">
    <!-- START - modal -->
    <AddPayRollModal />
    <PayRunDetailsModal />
    <DownloadABAFileModal />
    <PaymentDetailModal />
    <!-- END - modal -->

    <div class="element-wrapper" style="padding-bottom: 0px; ">
        <div style="display: flex;">
            <v-row style="height: 50px;">
                <v-col class="d-flex">
                    <v-select :items="dropDownYear" @change="onChange($event)" v-model="select" label="FY" item-text="name" item-value="value" dense outlined style="height: 40px !important;"></v-select>
                </v-col>
            </v-row>
            <h3  class="element-header" style="margin-bottom: 0px; justify-content: flex-end; display: flex; width: 100%">
                <a style="margin-top: 5px" @click="listPolicy.addPayroll ? openAddRunPayroll() : gotoPayment()" class='ml-2 mb-1 btn  ng-binding ng-scope btn-primary'>
                    Process Payroll
                </a>
            </h3>
        </div>
        <div class="payroll-content disable-scrollbars">
            <el-tabs type="border-card" v-model="tabs" @tab-click="handleClick" style="margin-top: 10px; background: rgb(248, 248, 248);">
                <el-tab-pane label="All" name="All">
                    <div class="row" style="margin-right: 10px;">
                        <div class="row" style="position: relative;">
                            <h6></h6>
                            <div v-show="isShowColumn" style="position: absolute; right: 5; background-color: transparent;">
                                <div class="text-right">
                                    <v-chip v-for="(item) in listColumn" :key="item.id" class="ma-2" x-small="true" close v-bind:text-color="(item.isActivated) ? 'white' : ''" v-bind:color="(item.isActivated) ? 'green':'grey'" v-bind:outlined="(item.isActivated) ? false:true" @click:close="clickItemcolumn(item)" v-bind:close-icon="(item.isActivated) ? 'mdi-minus-circle' : 'mdi-plus-circle'">
                                        {{item.name}}
                                    </v-chip>
                                </div>
                            </div>
                        </div>
                        <v-icon right class="wrench" @click="showChooseColumn()">mdi-wrench</v-icon>
                    </div>
                    <div class='element-content' style="margin-top: 15px;">
                        <div class='row ng-scope'>
                            <div class='col-xl-12' style="align-items: center;">
                                <div class='table-responsive' style="padding-bottom: 0px;">
                                    <table class="table table-padded" style="padding: 0px;">
                                        <thead class="">
                                            <tr>
                                                <th v-for="(item) in listColumn" :key="item.id" v-show="item.isActivated == true" v-bind:class="(item.name == 'Pay Date')?'text-center': item.name == 'Status' || item.name == 'PayPeriod' ? '' : 'text-right'">
                                                    <span v-on:click="sort(item.keySort , item.keySort == item.keySort ? false : true)" class="sort">{{item.name}} <i :class="(sortKey != item.keySort) ? 'icon-sort': (reverse) ? 'icon-sort-up':'icon-sort-down'"></i></span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item) in listPayRoll" :key="item.id" v-on:click="openEachPayRunModal(item)" class="animatedUp ng-scope" style="cursor: pointer">
                                                <td v-for="(i) in listColumn" :key="i.id" v-show="i.isActivated" v-bind:class="(i.name == 'Pay Date')?'text-center': i.name == 'Status' || i.name == 'PayPeriod' ? 'text-left' : 'text-right'">
                                                    <div v-if="i.name == 'Status'">
                                                        <span v-if="item.statusPayroll == 'Done'" class="badge status ng-binding bg-yellow ">Done</span>
                                                        <span v-else v-bind:class="'badge status ng-binding '+ (item.statusPayroll == 'Draft' ? 'bg-grey' : (item.statusPayroll == 'Pushed' ? 'bg-blue' : (item.statusPayroll == 'Error' ? 'bg-red' :(item.statusPayroll == 'Lodged' ? 'bg-green' : 'bg-pink'))))">{{item.statusPayroll}}</span>
                                                    </div>
                                                    <div v-if="i.name == 'PayPeriod'">
                                                        <span class="ng-binding text-left " style="font-size: 14px;"> {{formatDateFormat(item.startPayPeriod)}} - {{formatDateFormat(item.endPayPeriod)}}</span>
                                                        <span class="smaller lighter ml-0 ng-binding text-right" style="padding-left: 10px;">{{item.payRunPeriod}}</span>
                                                    </div>
                                                    <div v-if="i.name == 'PayDate'">
                                                        <span class="ng-binding"> {{formatDateFormat(item.paymentDate)}}</span>
                                                    </div>
                                                    <div v-if="i.name == 'Gross'">
                                                        <span class="ng-binding">${{roundNumber(item.totalEarnings)}}</span>
                                                    </div>
                                                    <div v-if="i.name == 'Tax'">
                                                        <span class="ng-binding">${{roundNumber(item.totalTax)}}</span>
                                                    </div>
                                                    <div v-if="i.name == 'Deduction'">
                                                        <span class="ng-binding">${{roundNumber(item.totalDeduction)}}</span>
                                                    </div>
                                                    <div v-if="i.name == 'Net'">
                                                        <span class="ng-binding">${{roundNumber(item.totalNetPay)}}</span>
                                                    </div>
                                                    <div v-if="i.name == 'Super'">
                                                        <span class="ng-binding">${{roundNumber(item.totalSuper)}}</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="Draft" name="Draft">
                    <div class="row" style="margin-right: 10px;">
                        <div class="row" style="position: relative;">
                            <h6></h6>
                            <div v-show="isShowColumnDraft" style="position: absolute; right: 5; background-color: transparent;">
                                <div class="text-right">
                                    <v-chip v-for="(item) in listColumnDraft" :key="item.id" class="ma-2" x-small="true" close v-bind:text-color="(item.isActivated) ? 'white' : ''" v-bind:color="(item.isActivated) ? 'green':'grey'" v-bind:outlined="(item.isActivated) ? false:true" @click:close="clickItemcolumn(item)" v-bind:close-icon="(item.isActivated) ? 'mdi-minus-circle' : 'mdi-plus-circle'">
                                        {{item.name}}
                                    </v-chip>
                                </div>
                            </div>
                        </div>
                        <v-icon right class="wrench" @click="showChooseColumnDraft()">mdi-wrench</v-icon>
                    </div>
                    <div class='element-content' style="margin-top: 15px;">
                        <div class='row ng-scope'>
                            <div class='col-xl-12' style="align-items: center;">
                                <div class='table-responsive' style="padding-bottom: 0px;">
                                    <table class="table table-padded">
                                        <thead class="">
                                            <tr>
                                                <th v-for="(item) in listColumnDraft" :key="item.id" v-show="item.isActivated == true" v-bind:class="(item.name == 'Pay Date')?'text-center': item.name == 'Status' || item.name == 'PayPeriod' ? '' : 'text-right'">
                                                    <span v-on:click="sort(item.keySort , item.keySort == item.keySort ? false : true)" class="sort">{{item.name}} <i :class="(sortKey != item.keySort) ? 'icon-sort': (reverse) ? 'icon-sort-up':'icon-sort-down'"></i></span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item) in listPayRollDraft" :key="item.id" v-on:click="openEachPayRunModal(item)" class="animatedUp ng-scope" style="cursor: pointer">
                                                <td v-for="(i) in listColumnDraft" :key="i.id" v-show="i.isActivated" v-bind:class="(i.name == 'Pay Date')?'text-center': i.name == 'Status' || i.name == 'PayPeriod' ? 'text-left' : 'text-right'">
                                                    <div v-if="i.name == 'Status'">
                                                        <span v-if="item.statusPayroll == 'Done'" class="badge status ng-binding bg-yellow ">Done</span>
                                                        <span v-else v-bind:class="'badge status ng-binding '+ (item.statusPayroll == 'Draft' ? 'bg-grey' : (item.statusPayroll == 'Pushed' ? 'bg-blue' : (item.statusPayroll == 'Error' ? 'bg-red' :(item.statusPayroll == 'Lodged' ? 'bg-green' : 'bg-pink'))))">{{item.statusPayroll}}</span>
                                                    </div>
                                                    <div v-if="i.name == 'PayPeriod'">
                                                        <span class="ng-binding text-left " style="font-size: 14px;"> {{formatDateFormat(item.startPayPeriod)}} - {{formatDateFormat(item.endPayPeriod)}}</span>
                                                        <span class="smaller lighter ml-0 ng-binding text-right" style="padding-left: 10px;">{{item.payRunPeriod}}</span>
                                                    </div>
                                                    <div v-if="i.name == 'PayDate'">
                                                        <span class="ng-binding"> {{formatDateFormat(item.paymentDate)}}</span>
                                                    </div>
                                                    <div v-if="i.name == 'Gross'">
                                                        <span class="ng-binding">${{roundNumber(item.totalEarnings)}}</span>
                                                    </div>
                                                    <div v-if="i.name == 'Tax'">
                                                        <span class="ng-binding">${{roundNumber(item.totalTax)}}</span>
                                                    </div>
                                                    <div v-if="i.name == 'Deduction'">
                                                        <span class="ng-binding">${{roundNumber(item.totalDeduction)}}</span>
                                                    </div>
                                                    <div v-if="i.name == 'Net'">
                                                        <span class="ng-binding">${{roundNumber(item.totalNetPay)}}</span>
                                                    </div>
                                                    <div v-if="i.name == 'Super'">
                                                        <span class="ng-binding">${{roundNumber(item.totalSuper)}}</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="Done" name="Done">
                    <div class="row" style="margin-right: 10px;">
                        <div class="row" style="position: relative;">
                            <h6></h6>
                            <div v-show="isShowColumnConfirmed" style="position: absolute; right: 5; background-color: transparent;">
                                <div class="text-right">
                                    <v-chip v-for="(item) in listColumnConfirmed" :key="item.id" class="ma-2" x-small="true" close v-bind:text-color="(item.isActivated) ? 'white' : ''" v-bind:color="(item.isActivated) ? 'green':'grey'" v-bind:outlined="(item.isActivated) ? false:true" @click:close="clickItemcolumn(item)" v-bind:close-icon="(item.isActivated) ? 'mdi-minus-circle' : 'mdi-plus-circle'">
                                        {{item.name}}
                                    </v-chip>
                                </div>
                            </div>
                        </div>
                        <v-icon right class="wrench" @click="showChooseColumnConfirmed()">mdi-wrench</v-icon>
                    </div>
                    <div class='element-content' style="margin-top: 15px;">
                        <div class='row ng-scope'>
                            <div class='col-xl-12' style="align-items: center;">
                                <div class='table-responsive' style="padding-bottom: 0px;">
                                    <table class="table table-padded">
                                        <thead class="">
                                            <tr>
                                                <th v-for="(item) in listColumnConfirmed" :key="item.id" v-show="item.isActivated == true" v-bind:class="(item.name == 'Pay Date')?'text-center': item.name == 'Status' || item.name == 'PayPeriod' ? '' : 'text-right'">
                                                    <span v-on:click="sort(item.keySort , item.keySort == item.keySort ? false : true)" class="sort">{{item.name}} <i :class="(sortKey != item.keySort) ? 'icon-sort': (reverse) ? 'icon-sort-up':'icon-sort-down'"></i></span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item) in listPayRollConfirmed" :key="item.id" v-on:click="openEachPayRunModal(item)" class="animatedUp ng-scope" style="cursor: pointer">
                                                <td v-for="(i) in listColumnConfirmed" :key="i.id" v-show="i.isActivated" v-bind:class="(i.name == 'Pay Date')?'text-center': i.name == 'Status' || i.name == 'PayPeriod' ? 'text-left' : 'text-right'">
                                                    <div v-if="i.name == 'Status'">
                                                        <span v-if="item.statusPayroll == 'Done'" class="badge status ng-binding bg-yellow ">Done</span>
                                                        <span v-else v-bind:class="'badge status ng-binding '+ (item.statusPayroll == 'Draft' ? 'bg-grey' : (item.statusPayroll == 'Pushed' ? 'bg-blue' : (item.statusPayroll == 'Error' ? 'bg-red' :(item.statusPayroll == 'Lodged' ? 'bg-green' : 'bg-pink'))))">{{item.statusPayroll}}</span>
                                                    </div>
                                                    <div v-if="i.name == 'PayPeriod'">
                                                        <span class="ng-binding text-left " style="font-size: 14px;"> {{formatDateFormat(item.startPayPeriod)}} - {{formatDateFormat(item.endPayPeriod)}}</span>
                                                        <span class="smaller lighter ml-0 ng-binding text-right" style="padding-left: 10px;">{{item.payRunPeriod}}</span>
                                                    </div>
                                                    <div v-if="i.name == 'PayDate'">
                                                        <span class="ng-binding"> {{formatDateFormat(item.paymentDate)}}</span>
                                                    </div>
                                                    <div v-if="i.name == 'Gross'">
                                                        <span class="ng-binding">${{roundNumber(item.totalEarnings)}}</span>
                                                    </div>
                                                    <div v-if="i.name == 'Tax'">
                                                        <span class="ng-binding">${{roundNumber(item.totalTax)}}</span>
                                                    </div>
                                                    <div v-if="i.name == 'Deduction'">
                                                        <span class="ng-binding">${{roundNumber(item.totalDeduction)}}</span>
                                                    </div>
                                                    <div v-if="i.name == 'Net'">
                                                        <span class="ng-binding">${{roundNumber(item.totalNetPay)}}</span>
                                                    </div>
                                                    <div v-if="i.name == 'Super'">
                                                        <span class="ng-binding">${{roundNumber(item.totalSuper)}}</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="Lodged" name="Lodged">
                    <div class="row" style="margin-right: 10px;">
                        <div class="row" style="position: relative;">
                            <h6></h6>
                            <div v-show="isShowColumnLoged" style="position: absolute; right: 5; background-color: transparent;">
                                <div class="text-right">
                                    <v-chip v-for="(item) in listColumnLoged" :key="item.id" class="ma-2" x-small="true" close v-bind:text-color="(item.isActivated) ? 'white' : ''" v-bind:color="(item.isActivated) ? 'green':'grey'" v-bind:outlined="(item.isActivated) ? false:true" @click:close="clickItemcolumn(item)" v-bind:close-icon="(item.isActivated) ? 'mdi-minus-circle' : 'mdi-plus-circle'">
                                        {{item.name}}
                                    </v-chip>
                                </div>
                            </div>
                        </div>
                        <v-icon right class="wrench" @click="showChooseColumnLoged()">mdi-wrench</v-icon>
                    </div>
                    <div class='element-content' style="margin-top: 15px;">
                        <div class='row ng-scope'>
                            <div class='col-xl-12' style="align-items: center;">
                                <div class='table-responsive' style="padding-bottom: 0px;">
                                    <table class="table table-padded">
                                        <thead class="">
                                            <tr>
                                                <th v-for="(item) in listColumnLoged" :key="item.id" v-show="item.isActivated == true" v-bind:class="(item.name == 'Pay Date')?'text-center': item.name == 'Status' || item.name == 'PayPeriod' ? '' : 'text-right'">
                                                    <span v-on:click="sort(item.keySort , item.keySort == item.keySort ? false : true)" class="sort">{{item.name}} <i :class="(sortKey != item.keySort) ? 'icon-sort': (reverse) ? 'icon-sort-up':'icon-sort-down'"></i></span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item) in listPayRollLodged" :key="item.id" v-on:click="openEachPayRunModal(item)" class="animatedUp ng-scope" style="cursor: pointer">
                                                <td v-for="(i) in listColumnLoged" :key="i.id" v-show="i.isActivated" v-bind:class="(i.name == 'Pay Date')?'text-center': i.name == 'Status' || i.name == 'PayPeriod' ? 'text-left' : 'text-right'">
                                                    <div v-if="i.name == 'Status'">
                                                        <span v-if="item.statusPayroll == 'Done'" class="badge status ng-binding bg-yellow ">Confirmed</span>
                                                        <span v-else v-bind:class="'badge status ng-binding '+ (item.statusPayroll == 'Draft' ? 'bg-grey' : (item.statusPayroll == 'Pushed' ? 'bg-blue' : (item.statusPayroll == 'Error' ? 'bg-red' :(item.statusPayroll == 'Lodged' ? 'bg-green' : 'bg-pink'))))">{{item.statusPayroll}}</span>
                                                    </div>
                                                    <div v-if="i.name == 'PayPeriod'">
                                                        <span class="ng-binding text-left " style="font-size: 14px;"> {{formatDateFormat(item.startPayPeriod)}} - {{formatDateFormat(item.endPayPeriod)}}</span>
                                                        <span class="smaller lighter ml-0 ng-binding text-right" style="padding-left: 10px;">{{item.payRunPeriod}}</span>
                                                    </div>
                                                    <div v-if="i.name == 'PayDate'">
                                                        <span class="ng-binding"> {{formatDateFormat(item.paymentDate)}}</span>
                                                    </div>
                                                    <div v-if="i.name == 'Gross'">
                                                        <span class="ng-binding">${{roundNumber(item.totalEarnings)}}</span>
                                                    </div>
                                                    <div v-if="i.name == 'Tax'">
                                                        <span class="ng-binding">${{roundNumber(item.totalTax)}}</span>
                                                    </div>
                                                    <div v-if="i.name == 'Deduction'">
                                                        <span class="ng-binding">${{roundNumber(item.totalDeduction)}}</span>
                                                    </div>
                                                    <div v-if="i.name == 'Net'">
                                                        <span class="ng-binding">${{roundNumber(item.totalNetPay)}}</span>
                                                    </div>
                                                    <div v-if="i.name == 'Super'">
                                                        <span class="ng-binding">${{roundNumber(item.totalSuper)}}</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>
            <div style="height: 13rem;">
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    GETLIST_RUNPAYROLL
} from "@/store/actionsType";
import AddPayRollModal from './modal/AddPayRollModal'
import PayRunDetailsModal from './modal/PayRunDetailsModal'
import DownloadABAFileModal from './modal/DownloadABAFileModal'
import PaymentDetailModal from './modal/paymentDetailModal'
import * as Utils from "@/utils/Utils.js";
import * as Session from "@/utils/SessionStore"
import moment from 'moment';

export default {
    name: "RunOverview",
    props: [],
    components: {
        AddPayRollModal,
        PayRunDetailsModal,
        DownloadABAFileModal,
        PaymentDetailModal
    },
    data() {
        return {
            listPolicy: null,
            sortKey: "",
            reverse: false,
            tabs: "All",
            listPayRoll: [],
            listPayRollConfirmed: [],
            listPayRollDraft: [],
            listPayRollLodged: [],
            endPayWeekly: "",
            endPayFortnightly: "",
            endPayMonthly: "",
            dropDownYear: [],
            select: this.getcurrentYear(),
            lastSubmitSTPId: 0,
            lastIdPayrollWeekly: "",
            lastIdPayrollFortnightly: "",
            lastIdPayrollMonthly: "",

            //Tab All
            isShowColumn: false,
            listColumn: [{
                    id: 0,
                    isActivated: true,
                    name: 'Status',
                    keySort: 'statusPayroll'
                },
                {
                    id: 1,
                    isActivated: true,
                    name: 'PayPeriod',
                    keySort: 'startPayPeriod'
                },
                {
                    id: 2,
                    isActivated: true,
                    name: 'PayDate',
                    keySort: 'paymentDate'
                },
                {
                    id: 3,
                    isActivated: true,
                    name: 'Gross',
                    keySort: 'totalEarnings'
                },
                {
                    id: 4,
                    isActivated: true,
                    name: 'Tax',
                    keySort: 'totalTax'
                },
                {
                    id: 5,
                    isActivated: true,
                    name: 'Deduction',
                    keySort: 'totalDeduction'
                },
                {
                    id: 6,
                    isActivated: true,
                    name: 'Net',
                    keySort: 'totalNetPay'
                },
                {
                    id: 7,
                    isActivated: true,
                    name: 'Super',
                    keySort: 'totalSuper'
                },
            ],

            //Tab Draft
            isShowColumnDraft: false,
            listColumnDraft: [{
                    id: 0,
                    isActivated: true,
                    name: 'Status'
                },
                {
                    id: 1,
                    isActivated: true,
                    name: 'PayPeriod'
                },
                {
                    id: 2,
                    isActivated: true,
                    name: 'PayDate'
                },
                {
                    id: 3,
                    isActivated: true,
                    name: 'Gross'
                },
                {
                    id: 4,
                    isActivated: true,
                    name: 'Tax'
                },
                {
                    id: 5,
                    isActivated: true,
                    name: 'Deduction'
                },
                {
                    id: 6,
                    isActivated: true,
                    name: 'Net'
                },
                {
                    id: 7,
                    isActivated: true,
                    name: 'Super'
                },
            ],

            //Tab Confirmed
            isShowColumnConfirmed: false,
            listColumnConfirmed: [{
                    id: 0,
                    isActivated: true,
                    name: 'Status'
                },
                {
                    id: 1,
                    isActivated: true,
                    name: 'PayPeriod'
                },
                {
                    id: 2,
                    isActivated: true,
                    name: 'PayDate'
                },
                {
                    id: 3,
                    isActivated: true,
                    name: 'Gross'
                },
                {
                    id: 4,
                    isActivated: true,
                    name: 'Tax'
                },
                {
                    id: 5,
                    isActivated: true,
                    name: 'Deduction'
                },
                {
                    id: 6,
                    isActivated: true,
                    name: 'Net'
                },
                {
                    id: 7,
                    isActivated: true,
                    name: 'Super'
                },
            ],

            //Tab Loged
            isShowColumnLoged: false,
            listColumnLoged: [{
                    id: 0,
                    isActivated: true,
                    name: 'Status'
                },
                {
                    id: 1,
                    isActivated: true,
                    name: 'PayPeriod'
                },
                {
                    id: 2,
                    isActivated: true,
                    name: 'PayDate'
                },
                {
                    id: 3,
                    isActivated: true,
                    name: 'Gross'
                },
                {
                    id: 4,
                    isActivated: true,
                    name: 'Tax'
                },
                {
                    id: 5,
                    isActivated: true,
                    name: 'Deduction'
                },
                {
                    id: 6,
                    isActivated: true,
                    name: 'Net'
                },
                {
                    id: 7,
                    isActivated: true,
                    name: 'Super'
                },
            ],
        };
    },
    created: function () {
        this.$root.$refs.RunOverview = this;
        this.listPolicy = this.$root.$refs.BaseLayout.listPolicy
        this.getListRunPayRoll()
        this.dropDownYear = [{
                name: this.getcurrentYear() - 2 + " - " + parseInt(this.getcurrentYear()-1),
                value: this.getcurrentYear() - 2
            },
             {
                name: this.getcurrentYear()-1 + " - " + parseInt(this.getcurrentYear()),
                value: this.getcurrentYear()-1
            },
            {
                name: this.getcurrentYear() + " - " + parseInt(this.getcurrentYear() + 1),
                value: this.getcurrentYear()
            }
        ]

        if (this.tabs === 'All') {
            if (Session.has('listColumnAll')) {
                for (var i = 0; i < this.listColumn.length; i++) {
                    this.listColumn[i].isActivated = Session.get('listColumnAll')[i].isActivated
                }
            }
        } else if (this.tabs === 'Draft') {
            if (Session.has('listColumnDraft')) {
                for (var j = 0; j < this.listPayRollDraft.length; j++) {
                    this.listPayRollDraft[j].isActivated = Session.get('listColumnDraft')[j].isActivated
                }
            }
        } else if (this.tabs === 'Confirmed') {
            if (Session.has('listColumnConfirmed')) {
                for (var z = 0; z < this.listColumnConfirmed.length; z++) {
                    this.listColumnConfirmed[z].isActivated = Session.get('listColumnConfirmed')[z].isActivated
                }
            }
        } else if (this.tabs === 'Lodged') {
            if (Session.has('listColumnLodged')) {
                for (var y = 0; y < this.listColumnLoged.length; y++) {
                    this.listColumnLoged[y].isActivated = Session.get('listColumnLodged')[y].isActivated
                }
            }
        }

    },

    methods: {
        getcurrentYear() {
            var dt = new Date()
            if ((dt.getMonth() + 1) >= 7) {
                var currentYear = dt.getFullYear()
            } else {
                currentYear = dt.getFullYear() - 1
            }
            return parseInt(currentYear)
        },
        onChange(any) {
            this.select = any
            this.getListRunPayRoll()
        },
        sort(column, boolean) {
            if (this.sortKey != column) {
                this.reverse = boolean;
                this.sortKey = column;
            } else {
                this.reverse = !this.reverse;
            }
            Utils.sortListJson(this.listPayRoll, this.sortKey, this.reverse);
            Utils.sortListJson(this.listPayRollConfirmed, this.sortKey, this.reverse);
            Utils.sortListJson(this.listPayRollDraft, this.sortKey, this.reverse);
            Utils.sortListJson(this.listPayRollLodged, this.sortKey, this.reverse);
        },
        roundNumber(number) {
            return Utils.roundNumber(number)
        },
        openAddRunPayroll() {
            this.$modal.show('modal-addpayroll', {
                endPayWeekly: this.endPayWeekly,
                endPayFortnightly: this.endPayFortnightly,
                endPayMonthly: this.endPayMonthly,
                lastIdPayrollWeekly: this.lastIdPayrollWeekly,
                lastIdPayrollFortnightly: this.lastIdPayrollFortnightly,
                lastIdPayrollMonthly: this.lastIdPayrollMonthly
            });
        },
        openEachPayRunModal(runpayroll) {
            var isShowUpdateUpdateLastSTP = false
            if (this.lastSubmitSTPId === runpayroll.id) {
                isShowUpdateUpdateLastSTP = true
            }
            this.$modal.show('modal-payrundetails', {
                runpayroll: runpayroll,
                isShowUpdateUpdateLastSTP: isShowUpdateUpdateLastSTP
            });
        },
        checkDate(date1, date2) {
            var d1 = new Date(date1);
            var d2 = new Date(date2);
            if (d1.getTime() > d2.getTime()) {
                return true
            }
            return false
        },
          formatDateFormat(inputDate, dateFormat) {
            var date = moment(inputDate);
            dateFormat = dateFormat || 'DD/MM/YYYY';
            if(date.isValid()) {
                return moment(inputDate).format(dateFormat);
            } else {
                return "-";
            }
        },
        formatDate(inputDate) {
            var m_names = new Array("Jan", "Feb", "Mar",
                "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec");
            var date = new Date(inputDate);
            var day = date.getDate();
            var month = date.getMonth();
            // var year = date.getFullYear();
            if (day.toString().length == 1) {
                day = "0" + day
            }
            var dateShow = day + " " + m_names[month];
            return dateShow
        },
        reloadDataRunOverView(isReload) {
            if (isReload) {
                this.getListRunPayRoll();
            }
        },

        gotoPayment() {
            this.$root.$refs.BaseLayout.gotoPayment()
        },

        clickItemcolumn(item) {
            item.isActivated = !item.isActivated
            if (this.tabs === 'All') {
                Session.set('listColumnAll', this.listColumn)
            } else if (this.tabs === 'Draft') {
                Session.set('listColumnDraft', this.listColumnDraft)
            } else if (this.tabs === 'Confirmed') {
                Session.set('listColumnConfirmed', this.listColumnConfirmed)
            } else if (this.tabs === 'Lodged') {
                Session.set('listColumnLodged', this.listColumnLoged)
            }
        },

        // Tab All
        showChooseColumn() {
            if (this.isShowColumn) {
                this.isShowColumn = false;
            } else {
                this.isShowColumn = true
            }
        },

        // Tab Draft
        showChooseColumnDraft() {
            if (this.isShowColumnDraft) {
                this.isShowColumnDraft = false;
            } else {
                this.isShowColumnDraft = true
            }
        },

        // Tab Confirmed
        showChooseColumnConfirmed() {
            if (this.isShowColumnConfirmed) {
                this.isShowColumnConfirmed = false;
            } else {
                this.isShowColumnConfirmed = true
            }
        },

        // Tab Loged
        showChooseColumnLoged() {
            if (this.isShowColumnLoged) {
                this.isShowColumnLoged = false;
            } else {
                this.isShowColumnLoged = true
            }
        },

        // Request API
        getListRunPayRoll() {
            if (Session.has("currenCompanyId")) {
                this.$root.$refs.AppCyrus.start()
                var modelRequest = {
                    companyId: Session.get("currenCompanyId"),
                    fromDate: this.select + "-07-01",
                    toDate: this.select + 1 + "-06-30",
                }
                this.$store.dispatch(GETLIST_RUNPAYROLL, modelRequest)
                    .then((response) => {
                        this.$root.$refs.AppCyrus.finish()
                        this.ongetListRunPayRollSuccess(response.reverse())
                    }).catch(() => {
                        this.$root.$refs.AppCyrus.fail()
                    });
            } else {
                if (this.$root.$refs.BaseLayout != undefined) {
                    this.$root.$refs.BaseLayout.logout()
                }
            }
        },

        ongetListRunPayRollSuccess(listPayRoll) {
            this.listPayRoll = listPayRoll
            this.endPayWeekly = ""
            this.endPayFortnightly = ""
            this.endPayMonthly = ""

            this.listPayRollConfirmed = []
            this.listPayRollDraft = []
            this.listPayRollLodged = []

            for (var i = 0; i < this.listPayRoll.length; i++) {
                if (this.listPayRoll[i].payRunPeriod == 'Weekly') {
                    if (this.endPayWeekly == '') {
                        this.endPayWeekly = this.listPayRoll[i].endPayPeriod
                    }
                    if (!this.checkDate(this.endPayWeekly, this.listPayRoll[i].endPayPeriod)) {
                        this.endPayWeekly = this.listPayRoll[i].endPayPeriod
                        if (this.lastIdPayrollWeekly === '' || this.lastIdPayrollWeekly < this.listPayRoll[i].id) {
                            this.lastIdPayrollWeekly = this.listPayRoll[i].id
                        }
                    }
                } else if (this.listPayRoll[i].payRunPeriod == 'Fortnightly') {
                    if (this.endPayFortnightly == '') {
                        this.endPayFortnightly = this.listPayRoll[i].endPayPeriod
                    }
                    if (!this.checkDate(this.endPayFortnightly, this.listPayRoll[i].endPayPeriod)) {
                        this.endPayFortnightly = this.listPayRoll[i].endPayPeriod
                        if (this.lastIdPayrollFortnightly === '' || this.lastIdPayrollFortnightly < this.listPayRoll[i].id) {
                            this.lastIdPayrollFortnightly = this.listPayRoll[i].id
                        }
                    }
                } else if (this.listPayRoll[i].payRunPeriod == 'Monthly') {
                    if (this.endPayMonthly == '') {
                        this.endPayMonthly = this.listPayRoll[i].endPayPeriod
                    }
                    if (!this.checkDate(this.endPayMonthly, this.listPayRoll[i].endPayPeriod)) {
                        this.endPayMonthly = this.listPayRoll[i].endPayPeriod
                        if (this.lastIdPayrollMonthly === '' || this.lastIdPayrollMonthly < this.listPayRoll[i].id) {
                            this.lastIdPayrollMonthly = this.listPayRoll[i].id
                        }
                    }
                }
                if (this.listPayRoll[i].statusPayroll == 'Draft') {
                    this.listPayRollDraft.push(this.listPayRoll[i])
                }
                else if (this.listPayRoll[i].statusPayroll == 'Done') {
                    this.listPayRollConfirmed.push(this.listPayRoll[i])
                } 
                else if (this.listPayRoll[i].statusPayroll == 'Pushed' || this.listPayRoll[i].statusPayroll == 'Lodged' ||
                    this.listPayRoll[i].statusPayroll == 'Error' || this.listPayRoll[i].statusPayroll == 'Paid') {
                    this.listPayRollLodged.push(this.listPayRoll[i])
                }
            }
            if (this.sortKey == '') {
                this.reverse = true;
                this.sortKey = 'paymentDate';
            }
            Utils.sortListJson(this.listPayRoll, this.sortKey, this.reverse);
            Utils.sortListJson(this.listPayRollConfirmed, this.sortKey, this.reverse);
            Utils.sortListJson(this.listPayRollDraft, this.sortKey, this.reverse);
            Utils.sortListJson(this.listPayRollLodged, this.sortKey, this.reverse);
            if (this.listPayRoll.length > 0) {
                this.lastSubmitSTPId = this.listPayRoll[0].id
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
@import url('/assets/css/runpayroll.css');
@import url("//unpkg.com/element-ui@2.13.1/lib/theme-chalk/index.css");
</style>
